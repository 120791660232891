<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 基本信息</div>
      <el-form ref="form" :rules="basicFormrules" :model="form" label-width="160px">
        <el-form-item label="客商名称：" prop="merchant_name">
          <el-input
            v-model="form.merchant_name"
            placeholder="请输入客商名称"
            style="width:300px" @blur="input_blur"
          />
        </el-form-item>
        <el-form-item label="助记码：" prop="mnemonic_code">
          <el-input
            v-model="form.mnemonic_code" 
            placeholder="助记码"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item :label="!id?'客商编号前缀：':'客商编号：'" prop="merchant_no">
            <el-select v-model="form.merchant_no" v-if="!id" placeholder="请选择"
                style="width: 300px; margin-right: 15px;" filterable>
                <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                    :key="index" :value="item.value"></el-option>
            </el-select>
            <el-input v-if="id" disabled style="width: 300px;" v-model="form.merchant_no"
                placeholder="请输入客商编号" />
        </el-form-item>
        <el-form-item label="客商编号：" prop="no">
            <el-input v-model="form.no" placeholder="请输入客商编号" style="width: 300px" />
        </el-form-item>
        <div class="con_title"><span></span> 营业执照</div>
        <el-form-item label="统一社会信用代码：" prop="bl_code">
          <el-input
            v-model="form.bl_code"
            placeholder="请输入统一社会信用代码"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="名称：" prop="bl_name">
          <el-input
            v-model="form.bl_name"
            placeholder="请输入名称"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="法定代表人：" prop="bl_legal_person">
          <el-input
            v-model="form.bl_legal_person"
            placeholder="请输入法定代表人"
            style="width:300px"
          />
        </el-form-item>
        
        <el-form-item label="成立时间:" prop="bl_established_date">
          <el-date-picker
            v-model="form.bl_established_date"
            type="date" style="width:300px"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="营业时间:" prop="bl_operating_period">
          <el-date-picker style="width:300px" 
            v-model="form.bl_operating_period"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="选择日期(长期不选)"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="住所：" prop="bl_residence">
          <el-input 
            v-model="form.bl_residence"
            placeholder="请输入住所"
            style="width:300px"
          />
        </el-form-item>
        <el-form-item label="经营范围：" prop="bl_business_scope">
          <el-input
            type="textarea"
            v-model="form.bl_business_scope"
            placeholder="请输入经营范围"
            style="width:450px"
          />
        </el-form-item>
        <div class="con_title"><span></span> 医疗器械经营许可证</div>
        <div class="flex flex_wrap" style="max-width: 1000px;">
            <el-form-item label="企业名称：" prop="mebl_company_name">
              <el-input
                v-model="form.mebl_company_name"
                placeholder="请输入企业名称"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="许可证编号：" prop="mebl_permit_number">
              <el-input
                v-model="form.mebl_permit_number"
                placeholder="请输入许可证编号"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="发证日期:" prop="mebl_issue_date">
              <el-date-picker
                v-model="form.mebl_issue_date"
                type="date" style="width:300px"
                value-format="YYYY-MM-DD"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="有效期时间:" prop="time">
              <el-date-picker
                v-model="form.time"
                type="daterange" style="width:300px"
                range-separator="至" value-format="YYYY-MM-DD"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            
            <el-form-item label="经营方式：" prop="mebl_operation_mode">
              <el-input
                v-model="form.mebl_operation_mode"
                placeholder="请输入经营方式"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="法定代表人：" prop="mebl_legal_person">
              <el-input
                v-model="form.mebl_legal_person"
                placeholder="请输入法定代表人"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="企业代表人：" prop="mebl_ceo">
              <el-input
                v-model="form.mebl_ceo"
                placeholder="请输入企业代表人"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="经营场所：" prop="mebl_business_premises">
              <el-input
                v-model="form.mebl_business_premises"
                placeholder="请输入经营场所"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="库房地址：" prop="mebl_warehouse_address">
              <el-input
                v-model="form.mebl_warehouse_address"
                placeholder="请输入库房地址"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="住所：" prop="mebl_residence">
              <el-input
                v-model="form.mebl_residence"
                placeholder="请输入住所"
                style="width:300px"
              />
            </el-form-item>
            <el-form-item label="经营范围：" prop="mebl_business_scope">
              <el-input
                type="textarea"
                v-model="form.mebl_business_scope"
                placeholder="请输入经营范围"
                style="width:300px"
              />
            </el-form-item>
        </div>
        <div class="con_title"><span></span> 审批信息</div>
        
        <div class="flex_ac" v-for="(item2,index2) in sh_arr" :key="index2">
            <el-form-item :label="item2.title+'意见：'" style="width:400px">
              <el-select v-model="item2.opinion" placeholder="请选择意见" style="width:100%">
                <el-option label="同意" value="1"></el-option>
                <el-option label="不同意" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经办人:">
              <el-select v-model="item2.admin_id" placeholder="请选择"
                  style="width: width:100% " filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in rylist" v-show="item['is_'+item2.key]==1"  
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核日期：" style="width:400px">
              <el-date-picker
                v-model="item2.audit_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:100%"
              >
              </el-date-picker>
            </el-form-item>
        </div>
    </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="activeHandle" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addEnterprise",
  data() {
    return {
      form: {},
      zjlist:[],
      id: '',
      is_ajax: false,
      sh_arr:[
          {admin_id:'',opinion:'1',title:'采购部',key:'purchase'},
          {admin_id:'',opinion:'1',title:'质管部',key:'quality'},
          // {admin_id:'',opinion:'1',title:'销售部',key:'sale'},
          {admin_id:'',opinion:'1',title:'总经理',key:'president'}
      ],
      basicFormrules: {
          merchant_name: [
            { required: true, message: "客商名称不能为空",trigger: "blur"}
          ],
          // merchant_no: [
          //   { required: true, message: "客商编号前缀不能为空",trigger: "blur"}
          // ],
          // mnemonic_code: [
          //   { required: true, message: "助记码不能为空",trigger: "blur"}
          // ],
          
          // bl_code: [
          //   { required: true, message: "统一社会信用代码不能为空",trigger: "blur"}
          // ],
          // bl_name: [
          //   { required: true, message: "名称不能为空",trigger: "blur"}
          // ],
          // bl_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // bl_established_date: [
          //   { required: true, message: "成立时间不能为空",trigger: "blur"}
          // ],
          // bl_residence: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // bl_business_scope: [
          //   { required: true, message: "经营范围不能为空",trigger: "blur"}
          // ],
          // mebl_company_name: [
          //   { required: true, message: "企业名称不能为空",trigger: "blur"}
          // ],
          // mebl_permit_number: [
          //   { required: true, message: "许可证编号不能为空",trigger: "blur"}
          // ],
          // mebl_issue_date: [
          //   { required: true, message: "发证日期不能为空",trigger: "blur"}
          // ],
          // mebl_operation_mode: [
          //   { required: true, message: "经营方式不能为空",trigger: "blur"}
          // ],
          // mebl_legal_person: [
          //   { required: true, message: "法定代表人不能为空",trigger: "blur"}
          // ],
          // time: [
          //   { required: true, message: "有效期不能为空",trigger: "blur"}
          // ],
          // mebl_ceo: [
          //   { required: true, message: "企业代表人不能为空",trigger: "blur"}
          // ],
          // mebl_business_premises: [
          //   { required: true, message: "经营场所不能为空",trigger: "blur"}
          // ],
          // mebl_residence: [
          //   { required: true, message: "住所不能为空",trigger: "blur"}
          // ],
          // mebl_warehouse_address: [
          //   { required: true, message: "库房地址不能为空",trigger: "blur"}
          // ],
          // mebl_business_scope: [
          //   { required: true, message: "经营范围不能为空",trigger: "blur"}
          // ],
          // mebl_imageIds: [
          //   { required: true, message: "证件图片不能为空",trigger: "blur"}
          // ],
      },
      rylist:[],
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          // this.basicFormrules.merchant_no[0].required = false;
          this.get_data()
      }else{
          for(let i=0;i<this.sh_arr.length;i++){
              this.sh_arr[i].audit_date=this.today_val()
          }
      }
      this.getOptions();
  },
  methods: {
    input_blur(){
        if(this.form.merchant_name){
          this.$httpGet("/backend/product.Product/getMnemonicCode", {
              product_name: this.form.merchant_name
          }).then((res) => {
              if (res.status == 200) {
                  this.form.mnemonic_code=res.data
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
        }else{
          this.form.mnemonic_code=''
        }
    },
    reset(pid) {
        this.form={
            merchant_no:this.zjlist.length>0?this.zjlist[0].value:''
        };
        this.sh_arr=[
          {admin_id:'',opinion:'1',title:'采购部',key:'purchase'},
          {admin_id:'',opinion:'1',title:'质管部',key:'quality'},
          // {admin_id:'',opinion:'1',title:'销售部',key:'sale'},
          {admin_id:'',opinion:'1',title:'总经理',key:'president'}
        ]
        if (this.$refs['form']) {
            this.$refs['form'].clearValidate();
        }
    },
    activeHandle() {
        let form='form';
        this.$refs[form].validate((valid) => {
            if (valid) {
                
                // 时间范围
                this.form.mebl_issue_date=this.form.time?this.form.time[0]:'';
                this.form.mebl_valid_until=this.form.time?this.form.time[1]:'';
                let _data={...this.form};
                if(this.id){
                    _data.id=this.id
                }
                
                let arr=[];
                for(let i=0;i<this.sh_arr.length;i++){
                    if(!this.sh_arr[i].admin_id){
                        this.$message.error('请选择'+this.sh_arr[i].title+'经办人');
                        return false;
                    }
                }
                // 提交
                this.is_ajax = true;
                _data.audit_array=JSON.stringify(this.sh_arr);
                this.$httpPost("/backend/EnterpriseFirst/save", _data).then((res) => {
                    if (res.status == 200) {
                        this.$message.success(this.id ? '更新成功' : '新增成功');
                        // if(this.id){
                            this.$router.go(-1)
                        // }else{
                        //     this.active=1;
                        //     this.$nextTick(()=>{
                        //         this.reset();
                        //     })
                        // }
                    } else {
                        this.$message.error(res.message);
                    }
                    this.is_ajax = false;
                }).catch((err) => {
                    console.log(err);
                    this.is_ajax = false;
                });
            }
        })
    },
    get_data(){
        this.$httpGet("/backend/EnterpriseFirst/read", {
            enterprise_first_id: this.id
        }).then((res) => {
            if (res.status == 200) {
                let _data = res.data;
                this.form=res.data
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '供应商编号'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(!this.id && this.zjlist.length>0){
                    this.form.merchant_no=this.zjlist[0].value
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        // 获取经办人
        this.$httpGet("/backend/sys.Admin/handlerList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data;
                
                // 默认选中第一个
                if(!this.id && this.rylist.length>0){
                    for(let i=0;i<this.sh_arr.length;i++){
                        if(this.sh_arr[i].admin_id==''){
                            for(let k=0;k<this.rylist.length;k++){
                                if(this.rylist[k]['is_'+this.sh_arr[i].key]=='1'){
                                    this.sh_arr[i].admin_id=this.rylist[k].id;
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
  
};
</script>

<style lang="scss" scoped>
.instrument {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>
